$(function(){

const tabs     = '.tab';
const label   = $(tabs).find('.tab-label');
const content = $(tabs).find('.tab-content');
const tag     = label.find('.label-item');
const current = 'current';
//
showTabContent();
//
tag.on('click', function(e){
  const self = $(this);
  e.preventDefault();
  label.find('li').removeClass(current);
  self.parent().addClass(current);
  showTabContent();
});
//
function showTabContent(){
  const id = $( '.' + current ).find('.label-item').attr('data-label');
  content.find('>*').hide();
  $('#' + id).fadeIn();
}


/**
*  PAGE TOP
*/
const pagetop = $('#pageTop');
$(window).on('scroll', function () {
  if( $(this).scrollTop() > 100 ) {
    pagetop.fadeIn();
  } else {
    pagetop.fadeOut();
  }
});
//
pagetop.on('click', function(){
  $('body, html').animate({ scrollTop : 0 }, 400);
  return false;
});

/**
*  ACCORDION
*/

$('.accordion dt').on('click', function(){
  $(this).next('dd').slideToggle(180);
  $(this).toggleClass('open');
  $(this).next('dd').siblings('dd').slideUp(100);
  $(this).siblings('dt').removeClass('open');
});

// ページ内リンク
$('a[href^="#"]').click(function(){
  var w = window.innerWidth;
  var height = 72;
  var speed = 500;
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top - height;
  $("html, body").animate({scrollTop:position}, speed, "swing");
  return false;
});

//アンカーリンクのズレ対応
$(window).on('load', function() {
  var hash = location.hash;
  if( hash.length > 0 ){
    var height = 72;
    var speed = 0;
    var target = $(hash == "#" || hash == "" ? 'html' : hash);
    var position = target.offset().top - height;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  }
});

toggles( 'js-accordion-trigger' );

});

/**
***  toggle  */
const toggles = ( trigger ) => {
  const triggerClassName = trigger;
  const parentClassName = 'js-trigger-parent';
  const activeClassName = 'active';
  const toggle = document.getElementsByClassName( triggerClassName );
  if ( toggle !== null && toggle !== undefined  ) {
    for ( let i = 0; i < toggle.length; i++ ){
      toggle[i].addEventListener('click', function( e ){
        e.preventDefault();
        //アクティブ判定
        const isActive = this.classList.contains( activeClassName );
        //クリックした要素の子メニュー
        const panel = this.nextElementSibling;
        //全トグルリスト
        const allToggles = document.getElementsByClassName(triggerClassName);
        //一度全部閉じる
        for ( let j = 0; j < allToggles.length; j++ ) {
          //除外条件
          if( allToggles[j] !== toggle[i] ){
            slideup( allToggles[j].nextElementSibling, 300 );
            allToggles[j].classList.remove( activeClassName );
          }
        };
        //開閉
        if( isActive ){
          this.classList.remove( activeClassName );
          slideup( panel, 300 );
        }else{
          this.classList.add( activeClassName );
          slidedown( panel, 300 );
        }
      });
    };
    //クリア
    window.addEventListener( 'resize', function() {
      if ( toggle !== null && toggle !== undefined  ) {
        for ( let i = 0; i< toggle.length; i++ ){
          toggle[i].classList.remove( activeClassName );
          toggle[i].nextElementSibling.style.display = '';
        }
      }
    });
  };
};

// slideUp
const slideup = (el, duration = 300) => {
  el.style.height = el.offsetHeight + "px";
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = "none";
    el.style.removeProperty("height");
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};

// slideDown
const slidedown = (el, duration = 300) => {
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") {
    display = "block";
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.height = height + "px";
  el.style.removeProperty("padding-top");
  el.style.removeProperty("padding-bottom");
  el.style.removeProperty("margin-top");
  el.style.removeProperty("margin-bottom");
  setTimeout(() => {
    el.style.removeProperty("height");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};

// slideToggle
const slidetoggle = (el, duration = 300) => {
  if (window.getComputedStyle(el).display === "none") {
    return slidedown(el, duration);
  } else {
    return slideup(el, duration);
  }
};

$(function(){
  $('body').append( '<div id="mobile_menu_wrapper"></div>' );
  $('body').append( '<div class="drawer-menu-overlay" id="overlay"></div>' );
  $('#nav').clone().addClass('sm_nav').appendTo('#mobile_menu_wrapper');
  $('.sm_nav > ul > li > div > span, .sm_nav > ul > li > div > a').unwrap();
  $('.sm_nav .trigger').addClass('js-trigger');
  $('.sm_nav .global_navigation_menu').attr('id', 'sm_global_navigation_menu');
  $('#js-burger').on( 'click', function(){
    $('body').toggleClass( 'open-menu' );
    $('#sm_global_navigation_menu').slideToggle(300);
  });
  $('#overlay').on( 'click', function() {
    $('body').removeClass( 'open-menu' );
  });
  $(document).on( 'click', '.js-trigger', function() {
    $(this).next('ul.child').slideToggle( 200 );
  });
});